<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
    <div class="mb-3 font-bold text-2xl">
      <span class="text-900">Tus puntos de venta</span>
    </div>

    <div v-if="organizations && organizations.length">
      <div class="text-700 text-sm mb-6">
        <p>Seleccione el punto de venta al que desea acceder.</p>
      </div>
      <div class="grid justify-content-center">
        <template
          v-for="(organization, index) of organizations"
          :key="organization.id"
        >
          <div class="col-6 md:col-2 mb-4 px-5">
            <router-link :to="'/organization/' + organization.id">
              <div class="flex flex-column align-items-center w-full">
                <div>
                  <span
                    v-if="organization.logo"
                    class="p-2 shadow-2 mb-3 inline-block surface-card"
                    style="border-radius: 10px"
                  >
                    <Image
                      imageStyle="width: 8em;"
                      :src="logosPath[index]"
                      :alt="organization.name + ' logo'"
                    />
                  </span>
                  <span
                    v-else
                    class="p-3 shadow-2 mb-3 inline-block surface-card"
                    style="border-radius: 10px"
                  >
                    <i class="pi pi-desktop text-4xl text-blue-500"></i>
                  </span>
                </div>
                <div class="text-900 mb-3 font-medium">
                  {{ organization.name }}
                </div>
                <div v-if="!organization.enabled">
                  <Badge value="Inactivo" severity="warning"></Badge>
                </div>
              </div>
            </router-link>
          </div>
        </template>
      </div>
    </div>
    <div class="text-700 text-sm mb-6">
      <p v-if="!(organizations && organizations.length)">
        Vaya, parece que no tienes ningún punto de venta.
      </p>
      <div class="flex flex-column align-items-center">
        <Button class="w-max" :disabled="!isOrganzationSlotFree" @click="createOrganization">Crear punto de venta</Button>
        <small class="mt-3" v-if="!isOrganzationSlotFree">Si desea crear nuevos puntos de venta debe 
          <router-link to="/pricing"
        >actualizar el plan</router-link
      ></small>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationService from '../service/OrganizationService';

export default {
  data() {
    return {
      organizations: null,
    };
  },
  organizationService: null,
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.organizationService
      .getOrganizationList()
      .then((data) => (this.organizations = data));
  },
  methods: {
    createOrganization() {
      this.$router.push('/organization/new');
    },
  },
  computed: {
    logosPath() {
      return this.organizations.map(
        (organization) => `${process.env.VUE_APP_BASE_URL}/${organization.logo}`
      );
    },
    isOrganzationSlotFree() {
      const activeOrganizaitons = this.$store.state.user.activeOrganizations;
      const maxOrganizations = this.$store.state.user.maxOrganizations;
      return activeOrganizaitons < maxOrganizations;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
